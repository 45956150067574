<template>
<b-container fluid class="mt-5 p-5">
 <b-row class="justify-content-md-center"  v-show="!sucesso">
<b-col cols="12" md="auto">
 <b-card class="mt-3" header="Validação Certificado"
        header-bg-variant="primary"
        header-text-variant="white"
 >
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-1"
      >
        <b-form-input
          id="input-1"
          v-model="nrcert"
          type="text"
          placeholder="Digite nº seu certificado"
          required
        ></b-form-input>
      </b-form-group>
      <br>
    
        <br><br>
      <b-button type="submit" variant="danger">Validar</b-button>
      <b-alert class='mt-3' v-bind:show="temmsg" v-bind:variant="classemsg"> {{mensagem}}</b-alert>
      <b-spinner v-show="loading" variant="success" ></b-spinner>
   
    </b-form>

  </b-card>
  </b-col>
 </b-row>


<b-row class="justify-content-md-center" v-show="sucesso">
<b-col cols="12" md="auto">
 <b-card class="mt-3" header="Situação do Certificado"
        header-bg-variant="primary"
        header-text-variant="white"
      
        footer-tag="footer"
        footer-bg-variant="primary"
        
       
 >
 <b-card-text class='text-center'>
 Certificado Válido<b-icon icon="check2-all" variant="success"></b-icon>
 </b-card-text>
 
 <b-card-text class='text-center'>
 Conferimos a <h2> {{certificado.nome}} </h2>
 </b-card-text>
 <b-card-text class='text-center'>
 o Certificado de  <b> {{certificado.situacao}} </b>
 </b-card-text>
 <b-card-text class='text-center'>
    no curso <strong>{{certificado.treinamento}}</strong>
 </b-card-text>
 <b-card-text class='text-center'>
    com carga horaria de <strong> {{certificado.carga}} </strong> horas
 </b-card-text>
 <b-card-text class='text-center'>
    concluido em <strong> {{certificado.dtconclusao}} </strong> 
 </b-card-text>

 <b-card-footer>
    <img
              id="logofooter"
              src="../assets/logo.png"
              alt="Actshare"
              class="card-img-bottom"
              heigth=50px />    
</b-card-footer>
  
 </b-card>
 </b-col>
</b-row>



</b-container>  
</template>
<script>
export default {
    data() {
        return {
            nrcert:'',
            mensagem:'',
            sucesso:false,
            error: "",
            loading:false,
            certificado: {
                nome:'',
                situacao:'',
                treinamento:'',
                carga:0,
                dtconclusao:''
            }
        }
    },
     methods: {
        onSubmit(event) {
            console.log(this.form)
            event.preventDefault()
            this.getCertificado()
            
        },
        getCertificado() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND + "ajax_get_certificado.php";
            const dataJson = 'nrcert='+this.nrcert
            fetch(url,{
                    method: "POST",
                    headers: { "Content-Type":"application/x-www-form-urlencoded" },
                    body: dataJson
               })
                .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                })
                .then((data) => {
                       
                    this.sucesso = data.status == "sucesso";
                    this.mensagem = data.message;
                    this.certificado = data;
                    this.loading = false;
                })
                .catch(function (error) {
                    this.error = error;
                });
        }
    },
    computed: {
        temmsg() {
            let aux = this.mensagem
            if (aux.length > 0) {
                return true
            } else {
                return false
            }
        },
        classemsg() {
            if (this.sucesso) {
                return "success"
            } else {
               return "danger"   
            }
        }

    },
}
</script>
<style scoped>
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #00a69c !important;
}

#logofooter {
  
  height: 100%;
  width: 300px;
  background-color:rgb(44, 62, 80);
}

</style>
