<template>
 <div>
    <b-spinner v-show="loading" variant="success" ></b-spinner>
    <b-table v-show="Logado" striped hover 
        :items="items" 
        :fields="fields"
        label-sort-asc=""
        label-sort-desc=""
        caption-html="<center>Meus Pedidos</center>"
        caption-top
    >

   <template #cell(liquido)="data">
        {{ data.value | currency("R$") }}
   </template>

   <template #cell(desconto)="data">
        {{ data.value | currency("R$") }}
   </template>

    <template #cell(show_details)="row">
        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
          {{ row.detailsShowing ? 'Esconde' : 'Ver'}} Items Pedido
        </b-button>
    
    </template>
    <template #row-details="row">
        <b-card>
          <b-row class="mb-2">
            <b-col>
                <act-ped-items-grid v-bind:pedido="row.item.id">
                </act-ped-items-grid>
            </b-col>
          </b-row>

          <b-button size="sm" @click="row.toggleDetails">Esconde Items Pedido</b-button>
        </b-card>
      </template>

    </b-table>
  </div>    
</template>

<script>
import { mapGetters } from "vuex"
import { formatDate } from 'date-utils-2020'
import ActPedItemsGrid from './ActPedItemsGrid.vue'
export default {
    components: {
        ActPedItemsGrid
    },
    data() {
        return {
          loading:false,
          error:'',  
          fields: [
          {
            key: 'id',
            sortable: true
          },
          {
              key: 'data',
              sortable:true,
              formatter: value => {
                  return formatDate(value, 'dd/MM/yyyy')
              }
          },
          {
              key: 'desconto',
              sortable:true
          },
          {
              key: 'liquido',
              sortable:true
          },
          {
              key: 'situacao',
              sortable:true
          },
          {
              key: 'transacao',
              sortable:true
          },
          {
              key: 'dtpagto',
              label:'Dt Pagto',
              sortable:true,
               formatter: value => {
                  return formatDate(value, 'dd/MM/yyyy hh:mm')
              }
          },
          {
              key:'show_details',
              label:'Itens Pedido'
          }

          ],
            items:[]
        }
    },
    computed: {
        ...mapGetters({
            ClientId:'getClientId',
            Logado:'getLogado'
        })
    },
    mounted() {
      this.getPedidos(this.ClientId)
    },
     watch: {
        $route(to,from) {
            console.log('de '+from+' para '+to)
            this.getPedidos(this.ClientId)
        }
     },
      
     methods: {
        getPedidos(id) {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND + "ajax_get_pedidos.php?c="+id
            fetch(url)
                .then((response) => {
                if (response.ok) {
                
                    return response.json();
                }
                })
                .then((data) => {
                    this.items = data;
                this.loading = false;
                })
                .catch(function (error) {
                     this.error = error;
                    console.log(error);
                });
        }

     }    
}
</script>

<style scoped>

</style>

