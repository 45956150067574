<template>
<div>
<b-spinner v-show="loading" variant="success" ></b-spinner>
    <b-table striped hover 
        :items="items" 
        :fields="fields"
        label-sort-asc=""
        label-sort-desc=""
        caption-html="<center>Intens do Pedido </center>"
        caption-top
    >
     <template #cell(preco)="data">
        {{ data.value | currency("R$") }}
   </template>

   <template #cell(vlrdesc)="data">
        {{ data.value | currency("R$") }}
   </template>

    <template #cell(liquido)="data">
        {{ data.value | currency("R$") }}
   </template>

    </b-table>
</div>
</template>

<script>
export default {
    props: {
        pedido: String
    },
    data() {
        return {
          loading:false,
          error:'',  
          fields: [
          {
            key: 'id',
            sortable: true
          },
          {
            key: 'produto',
            sortable: true
          },
          {
            key: 'qtde',
            sortable: true
          },
          {
            key: 'preco',
            sortable: true
          },
          {
            key: 'percdesc',
            label:'%',
            sortable: true
          },
          {
            key: 'vlrdesc',
            label:'Vlr Desconto',
            sortable: true
          },
          {
            key: 'liquido',
            sortable: true
          }
         


          ],
          items:[]
        }
    },
    mounted() {
        this.getItemsPedido()
    },
    methods: {
        getItemsPedido() {
            this.loading = true;
            const url = process.env.VUE_APP_BACKEND + "ajax_get_peditems.php?p="+this.pedido
            fetch(url)
                .then((response) => {
                if (response.ok) {
                
                    return response.json();
                }
                })
                .then((data) => {
                    this.items = data;
                this.loading = false;
                })
                .catch(function (error) {
                     this.error = error;
                    console.log(error);
                });
        }

     } 
}
</script>

<style scoped>

</style>

