<template>
<div>
  <b-container id="principal" fluid class="p-5" >
    <b-row class='mt-5'>
        
        <div class="col-md-6 col-sm-12 mx-auto" >

     <div class="video-container">
  <iframe :src="videohome" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
</div>

         
        </div>
       
      

    </b-row>
    
    
  </b-container>

<b-container fluid>
<act-lista-prod :id='id'> </act-lista-prod>
</b-container>

<b-container fluid text-center style="background-color: #002046;">
<act-footer ></act-footer>
</b-container>

 <b-modal ref="my-modal" 
 hide-footer 
 no-close-on-esc
 no-close-on-backdrop
 hide-header-close
 title="Termos de Uso">
      <div class="d-block text-center">
          
	<p>Utilizamos cookies essenciais e tecnologias semelhantes de acordo com a nossa <a href="#" v-on:click.self.prevent="$bvModal.show('modalpolitica_3')">politica de privacidade</a> e, ao continuar navegando, você concorda com estas condições.</p>


  <act-politica-privacidade
    :id='nomemodal'
    :texto='politicatexto'
    >
</act-politica-privacidade>

      </div>
     <b-button class="m-2" variant="outline-success" block @click="aceitarpolitica">Aceitar</b-button>
    </b-modal>



</div> 
   

</template>

<script>
import { mapGetters } from "vuex"
import ActListaProd from './ActListaProd.vue'
import ActFooter from './ActFooter.vue'

import ActPoliticaPrivacidade from "./ActPoliticaPrivacidade.vue"


//import ActCarrinho from './ActCarrinho.vue'
export default {
  props: ['id'],
  data() {
    return {
       nomemodal:"3"
    }
  },
    
  
  components: {
     ActListaProd,
     ActFooter,
     ActPoliticaPrivacidade
    
  } ,
   computed: {
  ...mapGetters( {texto1:'getTxt1',
                  titulo1:'getTituloTxt1',
                  videohome:'getVideoHome',
                  ExibirPolitica:'getAceitarPolitica',
                  qtdTestemunhos:'getQtdTestemunhos'
                  }),
    politicatexto() {
      return this.$store.getters.getPoliticaTexto
    }                  
    
   }, 

   mounted() {
      if (this.ExibirPolitica) {
        
         this.$refs['my-modal'].show()
       
      }
   },
   methods: {
      aceitarpolitica() {
          sessionStorage.setItem("politicaseglgpd","1")
          this.hideModal()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      }
   }

  
  
}
</script>

<style scoped>
  a {
      color: #00a69c;
  }
  #principal {
   background-image: url('../assets/backgroundactshare.png');
  }
  #logoseg {
    margin-top:-16px;

  }
  .direita {
  text-align: right;
  }

.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>