<template>
 <div>
  <b-card >
    <b-card-text>
      <h4>{{titulo}}</h4>
      <b-form-group  v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        id="checkbox-group-prod"
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="grpprod"
      >
        <b-form-checkbox v-for="opcao in options" v-bind:key="opcao.value" v-bind:value="opcao.value">&nbsp; {{opcao.text}}</b-form-checkbox>
        
      </b-form-checkbox-group>
    </b-form-group>

   <!--  <div>Selected: <strong>{{ selected }}</strong></div> -->

    </b-card-text>
  </b-card>
</div>  
</template>
<script>
export default {
 
    data() {
        return {
            selected: [],
            options: [],
            loading:false,
            erro:'',
            show:false
        }

    },
    watch: {
        selected: function(newvalue) {
           
           this.$store.commit("setFiltroGrpProd",newvalue) 
        },
        $route(to) {
          this.selected = [to.params.id]
          //alert(to.params.id)
        }
    },
    props: ['titulo','id'],
    methods: {
       getGrupoProd() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "getGrupoProd.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.options = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    }, 
    mounted() {
        console.log('listaprod mounted '+this.id)
        console.log('parametro url '+ this.$route.params.id)
           this.selected = [this.id]
          // this.$store.commit("setFiltroGrpProd",this.id)
        
        this.getGrupoProd();

    }
}
</script>
<style scoped>

</style>