<template>

  <b-card
  
    v-bind:img-src="imgfoto"
    img-alt="Image"
    img-top
    img-height="200"
    img-width="250"
    tag="article"
    align="center"
    class="mb-3"
  >
    <b-card-text>
    <div style="height:80px">
{{prod.title}}
    </div>
    </b-card-text>

<act-modal-texto 
    :texto="prod.descricao"
    :titulo="prod.title"
    :id="nomemodal"
    class="mb-2"
>
</act-modal-texto>   





    <b-card-text>
<span v-if="prod.entranocarrinho == 'S'"  class="text-primary mx-auto">{{ prod.price | currency("R$") }}</span>
<span v-else class="text-primary mx-auto">&nbsp;</span>
    
<div class="text-primary mx-auto">{{ prod.complemento }}</div>
<div class="text-primary mx-auto">{{ prod.complemento2 }}</div>
<div class="text-primary mx-auto">{{ prod.complemento3 }}</div>
<span v-show="prod.entranocarrinho != 'S'" class="text-primary mx-auto"></span>

<div v-show="prod.entranocarrinho == 'N'"  class="text-primary mx-auto">&nbsp;</div>
<div v-show="prod.entranocarrinho == 'N'"  class="text-primary mx-auto">&nbsp;</div>

    </b-card-text>


    <template #footer>
    <b-row>
      <b-col>
        <b-button class="mt-2" v-if="prod.entranocarrinho == 'S'" block href="#" variant="danger" @click="clickAdd(prod,$event)">
          <b-icon icon="cart-plus"></b-icon>
          Carrinho
        </b-button> 

        <div v-else>
           <b-button v-b-modal.my-modal-consulte variant="primary">
            <b-icon icon='search'></b-icon>
           Consulte
           
           </b-button>
           <b-modal id="my-modal-consulte" title="Entre me Contato">
            <b-row v-html="textoconsulta" class="m-3">
             {{textoconsulta}} 
            </b-row> 
           </b-modal>
        </div>
      </b-col>
      <b-col >  
<act-desc-prog v-if="prod.entranocarrinho == 'S'"
    :texto="textodesconto"
    :titulo="prod.title"
    :id="nomemodal"
    class="mb-2"
>
</act-desc-prog>
      </b-col>
    </b-row>  
    </template>

    </b-card>
   
</template>

<script>
import { mapGetters } from "vuex"
import ActModalTexto from './ActModalTexto.vue'
import ActDescProg from './ActDescProg.vue'
export default {
  components: {
       ActModalTexto,
       ActDescProg
    },
    data: function() {
      return {
        id:1000,
        titulo:"Entre em Contato"
      }  
    },
     props: ['prod','foto','entranocarrinho'],
     computed: {
         nomemodal: function() {
            return '_prd_'+this.prod.id
         },
         imgfoto: function() {
               return this.foto

         } ,
         fone() {
           return this.$store.getters.getFone
         },
         email() {
           return this.$store.getters.getEmail
         },
         textodesconto() {
           return this.$store.getters.getTxtDescProg
         },
         textoconsulta() {
            return this.fone+"<br>email:"+this.email
         },
          ...mapGetters(['getCartItems'])
         
     },
     methods: {
          clickAdd: function (prod,e) {
            
          console.log(e)
           
          let itemToAdd = prod;
         
          // add the item or increase quantity
          let itemInCart = this.getCartItems.filter(
            (item) => item.id === itemToAdd.id
          );
          let isItemInCart = itemInCart.length > 0;

          if (isItemInCart === false) {
            this.$store.commit('addItemCart',itemToAdd)
            

            this.$bvToast.toast('Item adicionado com sucesso', {
              title: "Adicionado ao carrinho",
              variant: 'success',
              autoHideDelay: 5000,
              solid: true
            })


          } else {
            itemInCart[0].quantity += itemToAdd.quantity;
          }
          itemToAdd.quantity = 1;
        },
        abrirConsulte() {
          
        }
     }
     

}
</script>
  


<style scoped>

.btn-primary {
  color:#fff;
  background-color: #002046;
  border-color: #002046;
}

.btn-danger {
  color:#fff;
  background-color: #002046;
  border-color: #002046;
}
p {
      font-family: 'Poppins' !important;
  }
</style>