<template>
  <div>

  <b-button v-b-modal="nomemodal" variant="primary">
         <b-icon icon='three-dots'></b-icon>
          Ver Mais
  </b-button>
  
  <b-modal :id="nomemodal" :title="titulo">
    <p class="my-4" v-html="texto">
      {{texto}}
    </p>
  </b-modal>
</div> 
</template>

<script>
export default {
    computed: {
       nomemodal() {
         return 'modal_'+this.id
       }
    },
    props:['texto','id','titulo']
}
</script>

<style scoped>

</style>