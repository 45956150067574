<template>
    <b-modal :id="nomemodal" ok-only hide-header-close scrollable title="Política de Privacidade">
  
     <b-row v-html="texto">
      {{texto}}
     </b-row>                       


</b-modal>

</template>

<script>
export default {
   computed: {
       nomemodal() {
         return 'modalpolitica_'+this.id
       }
    },
    props:['id','texto'] 
}
</script>

<style scoped>

</style>