<template>
 <div>
  <b-card >
    <b-card-text>
      <h4>{{titulo}}</h4>
    <b-form-group  v-slot="{ ariaDescribedby }">
      <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="L">&nbsp; Livre</b-form-radio>
      <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="P">&nbsp; Pago</b-form-radio>
      <b-form-radio v-model="selected" :aria-describedby="ariaDescribedby" name="some-radios" value="T">&nbsp; Todos</b-form-radio>
    </b-form-group>

    <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->

    </b-card-text>
  </b-card>
</div>  
</template>
<script>
export default {
    data() {
        return {
            selected:"T",
            options: [],
            loading:false,
            erro:'',
            show:false
        }

    },
    watch: {
        selected: function(newvalue) {
           this.$emit('mudouLivrePago',newvalue)
        }
    },
    props: ['titulo'],
    
}
</script>
<style scoped>

</style>