<template>
 <div>
  <b-navbar  toggleable="lg" style="background-color: #002046; " type="dark" fixed="top" >
    <b-navbar-brand href="https://actshare.com.br" target="_blank" class="mx-4">
    <img
              id="logo"
              src="../assets/logo_menor_lgpd.png" 
              alt="Actshare" 
              
            >
    </b-navbar-brand>

  <act-sidebar-cart id='cartmobile' class='mx-5'></act-sidebar-cart>

<b-navbar-brand>

</b-navbar-brand>


    <b-navbar-toggle  target="nav-collapse" ></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav >
      
      <b-navbar-nav class="m-4" fill>
        

        <b-nav-item v-if="logado" href="#">Olá {{nomeusuario}}</b-nav-item>

 <b-nav-item href="https://all.actshare.com.br/sistema/app_Login/" target="_blank">Plataforma</b-nav-item>
<!--  <b-nav-item :to="{ name: 'Parceiro'}">Parceiros</b-nav-item> -->  
<b-nav-item :to="{ name: 'Certificado'}">Certificado</b-nav-item>

<b-nav-item href="#" v-on:click.self.prevent="$bvModal.show('modalpolitica_0')">Politica de Privacidade</b-nav-item>
<!-- <b-nav-item href="https://actshare.com.br/" target="_blank">ActShare</b-nav-item> --> 
  <b-nav-text>  <act-sidebar-cart id="cartdesk"></act-sidebar-cart>  </b-nav-text>
      </b-navbar-nav>

  

    </b-collapse>
  
  </b-navbar>
  <act-politica-privacidade
    :id='nomemodal'
    :texto='politicatexto'
    >
</act-politica-privacidade>

</div>
</template>

<script>
import ActPoliticaPrivacidade from "./ActPoliticaPrivacidade.vue"
import ActSidebarCart from "./ActSidebarCart.vue"
export default {
  components: {
    ActPoliticaPrivacidade,
    ActSidebarCart
  },
  data() {
    return {
      tipData: { title: '<em>Para ver Pedidos e ter uma experiência personalizada acesse sua conta</em>' },
      tipData2: { title: '<em>É Cliente novo ? Cria sua conta para ver Pedidos e ter uma experiência personalizada</em>' },
      nomemodal:"0"
    }
  },
  computed: {
    logado() {
      return this.$store.getters.getLogado
    },
    nomeusuario() {
      return this.$store.getters.getNomeLog
    },
    qtdcart() {
      return this.$store.getters.getQtdCarrinho
    },
    politicatexto() {
      return this.$store.getters.getPoliticaTexto
    }
  },
  methods: {
    sair() {
      console.log('vou sair')
      this.$store.dispatch('logout',false)
    }
  }

}
</script>

<style scoped>

@media (max-width: 575.98px) {
 #cartdesk {
  display: none !important;
 }
 #cartmobile {
  display: block !important;
 }
}
@media (min-width: 577px)  {
 #cartmobile {
  display: none !important;
 }
 #cartdesk {
  display: block !important;
 }
}


.numqtdcart {
  font-size: 8px !important;
  position:relative;
  top:-5px;
  color:yellow;
}



#nav-collapse {
  display:flex;
  justify-content: flex-end;
 left:200px;
  
}
.navbar-dark .navbar-nav .nav-link {
    color: white;
}
</style>
