<template>
<div>
   <span> redirecionando ... {{id}}</span>
</div>    
</template>
<script>
export default {
    props: ['id'],

    created() {

       this.$store.commit("setFiltroGrpProd",this.id)
       this.$router.push('/')
    }

}
</script>
<style scoped>

</style>
