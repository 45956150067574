<template>
 <div>
  <b-card >
    <b-card-text>
      <h4>{{titulo}}</h4>
      <b-form-group  v-slot="{ ariaDescribedby }">
      <b-form-checkbox-group
        id="checkbox-group"
        v-model="selected"
        :aria-describedby="ariaDescribedby"
        name="categoria"
      >
        <b-form-checkbox v-for="opcao in options" v-bind:key="opcao.value" v-bind:value="opcao.value">&nbsp; {{opcao.text}}</b-form-checkbox>
        
      </b-form-checkbox-group>
    </b-form-group>

   <!--  <div>Selected: <strong>{{ selected }}</strong></div> -->



    </b-card-text>
  </b-card>
</div>  
</template>
<script>
export default {
    data() {
        return {
            selected: [],
            options: [],
            loading:false,
            erro:'',
            show:false
        }

    },
    watch: {
        selected: function(newvalue) {
           this.$emit('mudouFiltro',newvalue)
        }
    },
    props: ['titulo'],
    methods: {
       getCategorias() {
      
      this.loading = true;
      const url = process.env.VUE_APP_BACKEND + "ajax_get_categorias.php";
      fetch(url)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.options = data;
          this.loading = false;
        })
        .catch(function (error) {
          this.error = error;
        });
      },

    }, 
    mounted() {
        console.log('created')
        this.getCategorias();

    }
}
</script>
<style scoped>

</style>