<template>
  <div id="app">
    <act-head/>
       <keep-alive>
			<router-view></router-view>
		</keep-alive>
  </div>
</template>

<script>
import ActHead from './components/act-head.vue'


export default {
  name: 'App',
  components: {
    ActHead,
 
  },
  created() {
    document.title = "actchare"
    console.log('vou fazer dispatch loadempresa')
    this.$store.dispatch("loadempresa")
    if (sessionStorage.getItem("politicaseglgpd") === null) {
      this.$store.commit('setAceitarPolitica',true)
    }  
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
</style>
