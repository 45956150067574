<template>

<div>
    <button class="mb-2" v-on:click="gerarf2b">Continuar </button> 
    <b-spinner v-show="loading" variant="success" ></b-spinner>
    <div ref="paypal"></div>
    <div v-if="cobgerada">
        <b-alert variant="success" show> 
            Este mesmo link <b-link :href="linkf2b" target="_blank"> {{linkf2b}} </b-link> foi enviado pro seu email.
        </b-alert>
	</div>
</div>

</template>

<script>
import { mapGetters } from "vuex";
export default {
    data: function() {
        return {
        loading:false,  
        cobgerada: false,
        linkf2b:''
        }  
    },
    computed: {
        ...mapGetters({
            itensCarrinho: "getCartItems",
            Total:'getTotalCarrinho',
            TotalDesc:'getDescontoCarrinho',
            QtdCarrinho:'getQtdCarrinho',
            CupomId:'getCupomId',
            PerCupom:'getPerCupom',
            CupomVlr:'getCupomVlr',
            ClientId:'getClientId',
            emailpaypal:'getEmailPaypal'
        }),
         VlrCupom() {
           if (this.CupomVlr > 0) {
               return this.CupomVlr
            } else if (this.PerCupom < 0) {
                    return 0
            } else {
                return (this.Total - this.TotalDesc) * this.PerCupom / 100    
            }
        },
        ComDesc() {
        return this.Total - this.TotalDesc
        },
         Liquido() {
            let vlrfinal = this.Total - this.TotalDesc - this.VlrCupom
            if (vlrfinal >= 0) {
              return vlrfinal
            } else {
              return 0.00
            }
        }
   },
   methods: {
       gerarf2b() {
            this.loading = true
            const url = process.env.VUE_APP_BACKEND +'AprovePaypal.php'
            let dados = "clientid="+this.ClientId
                dados += '&gateway=2'
                dados += '&total='+this.Total
                dados += '&cupomid='+this.CupomId
                dados += '&vlrcupom='+this.VlrCupom
                dados += '&vlrliquido='+this.Liquido
                dados += '&qtditens='+this.QtdCarrinho
                let extra = this.VlrCupom + this.TotalDesc
                if (extra > 0) {
                    extra = extra * -1
                    dados += '&extraAmount='+extra
                }
                for (let i = 0; i < this.itensCarrinho.length; i++) {
                            let item = this.itensCarrinho[i]
                            let indice = i + 1
                            dados += '&itemId'+indice+'='+item.id
                            dados += '&itemDescription'+indice+'='+item.title
                            dados += '&itemAmount'+indice+'='+item.price
                            dados += '&itemQuantity'+indice+'='+item.quantity
                }
                fetch(url,{
                            method: "POST",
                            headers: { "Content-Type":"application/x-www-form-urlencoded" },
                            body: dados
                        })
                         .then((response) => {
                                if (response.ok) {
                                
                                    return response.json();
                            }
                            })
                            .then((json) => {
                                    this.loading = false
                                    if (json.status == 'sucesso') {
                                        this.$store.commit('setPdId',json.id)
                                        this.linkf2b = json.message
                                        this.cobgerada = true
                                    } else {
                                        alert(json.message)
                                    }
                            })
                            .catch(function (error) {
                                console.log(error);
                            })


        }
   },
   watch: {
        cobgerada: function(newValue) {
           if (newValue) {
               const h = this.$createElement
        // Using HTML string
        const titleVNode = h('div', { domProps: { innerHTML: ' ' } })

                       // More complex structure
        const messageVNode = h('div', { class: ['foobar'] }, [
          h('p', { class: ['text-center'] }, [
            ' Clique ',

           h('b-link',{ 
                props: { 
                    href: this.linkf2b,
                    target:'_blank'
                } },'aqui'),
           ]),

            ' para escolher entre Boleto ou Pix. ATENCÃO: ao optar por pagamento por boleto, aguarde pelo menos 02 (duas) horas para efetuar o pagamento, pois este é o tempo necessário para que o boleto seja reconhecido pelo sistema bancário. ',
            ])

 // We must pass the generated VNodes as arrays
        this.$bvModal.msgBoxOk([messageVNode], {
          title: [titleVNode],
          buttonSize: 'sm',
          centered: true, size: 'sm'
        })
         
        
       
            }
       }
   }

}
</script>

<style scoped>

</style>