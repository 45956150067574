import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({ 

    state: {
        pdid:0,
        cartItems:[],
        tabdesc:[],
        logado:false,
        nomelog:'',
        clientid:0,
        msglog:'',
        contacriada:false,
        msgconta:'',
        enviouparceiro:false,
        msgparceiro:'',
        txt1:'',
        titulotxt1:'',
        txtparceiro:'',
        cupom:'',
        percupom:0.00,
        cupomvlr:0.00,
        parceiroped:0,
        cupomid:0,
        politica:"not_accept",
        aceitarpolitica:false,
        politicatexto:'',
        txtdescprog:'',
        fone:'',
        email:'',
        emailpaypal:'',
        videohome:'',
        qtdtestemunhos:0,
        filtrogrpprod:'',
        qtdparc:1


    },
    getters: {
        getQtdTestemunhos(state) {
            const url = process.env.VUE_APP_BACKEND +'ajax_get_qtdtestemunhos.php'
            fetch(url,{
                method: "POST",
                headers: { "Content-Type":"application/x-www-form-urlencoded" },
                
            })
            .then((response)  => { return response.json()
            })
            .then((data) => {
                    console.log('qtd testemunhos '+data.qtd)
                    return state.qtdtestemunhos = data.qtd;          
                   // context.commit("setEnviouParceiro",data.status)
                   // context.commit("setMsgParceiro",data.message)
             
            })
            .catch(function(error) {
                                   console.log('Erro Qtd Testemunhos: '+error);
            })                
        },
        getQtdParc(state) {
            return state.qtdparc
        },
        getFiltroGrpProd(state) {
            return state.filtrogrpprod
        },
        getPolitica(state) {
            return state.politica
        },
        getPoliticaTexto(state) {
            return state.politicatexto
        },
        getAceitarPolitica(state) {
            return state.aceitarpolitica
        },
        getEmail(state) {
            return state.email
        },
        getEmailPaypal(state) {
            return state.emailpaypal
        },
        getFone(state) {
            return state.fone
        },
        getVideoHome(state) {
            return state.videohome
        },
        getCupomId(state) {
            return state.cupomid
        },
        getPdId(state) {
            return state.pdid
        },
        getParceiroPed(state) {
            return state.parceiroped
        },
        getTxt1(state) {
            return state.txt1
        },
        getTituloTxt1(state) {
            return state.titulotxt1
        },
        getTxtParceiro(state) {
            return state.txtparceiro
        },
        getTxtDescProg(state) {
            return state.txtdescprog
        },
        getMsgParceiro(state) {
            return state.msgparceiro
        },
        getEnviouParceiro(state) {
            if (state.enviouparceiro == "sucesso") {
                return true
            } else {
                return false
            }
        },
        getContaCriada(state) {
           if (state.contacriada == "sucesso") {
               return true
           } else {
               return false
           }
        },
        getMsgConta(state) {
            return state.msgconta
        },
        getLogado(state) {
            return state.logado                
        },
        getNomeLog(state) {
            return state.nomelog
        },
        getClientId(state) {
            return state.clientid
        },
        getMsgLog(state) {
            return state.msglog
        },
        getPerCupom(state) {
            return state.percupom
        },
        getCupomVlr(state) {
            return state.cupomvlr;
        },
        getCartItems(state) {
            return state.cartItems
        },
        getQtdCarrinho(state) {
            return state.cartItems.length                
        },
        getTotalCarrinho(state) {
            let total = 0;
			state.cartItems.forEach(item => {
				total += (item.price * item.quantity);
			});
			return total;
        },
        getDescontoCarrinho(state) {
            let totaldesc = 0;
			state.cartItems.forEach(function(item)  {

                let subtotal = item.price * item.quantity

                let percdesc = 0
				state.tabdesc.forEach(function(tab)  {
					if ((item.quantity >=  tab.qtdmin) && (item.quantity <= tab.qtdmax)) {
							percdesc = tab.desc
					}
				})


             
				let vlrdesc = subtotal * percdesc / 100 
				totaldesc += vlrdesc
			});
			return totaldesc;
        }
    },
    mutations: {
        addItemCart(state,payload) {
            state.cartItems.push(payload)
        },
        setQtdParc(state,payload) {
            state.qtdparc = payload
        },
        setPolitica(state,payload) {
            state.politica = payload
        },
        setFiltroGrpProd(state,payload) {
            state.filtrogrpprod = payload
        },
        setPoliticaTexto(state,payload) {
            state.politicatexto = payload
        },
        setAceitarPolitica(state,payload) {
            state.aceitarpolitica = payload
            console.log('Aceitar Politica '+payload)
        },
        setEmail(state,payload) {
            state.email = payload
        },
        setEmailPaypal(state,payload) {
            state.emailpaypal = payload
        },
        setFone(state,payload) {
            state.fone = payload
        },
        setVideoHome(state,payload) {
            state.videohome = payload
        },
        setPdId(state,payload) {
            state.pdid = payload
        },
        setCupomId(state,payload) {
            state.cupomid = payload
        },
        setTabDesc(state,payload) {
            state.tabdesc = payload
        },
        delItemCart(state,payload) {
            state.cartItems.splice(payload, 1);
        },
        setLogado(state,payload) {
            state.logado = payload
        },
        setNomeLog(state,payload) {
            state.nomelog = payload
        },
        setMsgLog(state,payload) {
            state.msglog = payload
        },
        setClientId(state,payload) {
            state.clientid = payload
        },
        setContaCriada(state,payload) {
            state.contacriada = payload
        },
        setMsgConta(state,payload) {
            state.msgconta = payload
        },
        setMsgParceiro(state,payload) {
            state.msgparceiro = payload
        },
        setEnviouParceiro(state,payload) {
            state.enviouparceiro = payload
        },
        setTxt1(state,payload) {
            state.txt1 = payload
        },
        setTituloTxt1(state,payload) {
            state.titulotxt1 = payload
        },
        setTxtParceiro(state,payload) {
            state.txtparceiro = payload
        },
        setTxtDescProg(state,payload) {
            state.txtdescprog = payload
        },
        setCupom(state,payload) {
            state.cupom = payload
        },
        setCupomVlr(state,payload) {
            state.cupomvlr = payload
        },
        setPerCupom(state,payload) {
            state.percupom = payload
        },
        setParceiroPed(state,payload) {
            state.parceiroped = payload
        }
       
    },
    actions: {
        loadempresa(context) {
           
            const url = process.env.VUE_APP_BACKEND +'ajax_empresa.php'
            //const dataJson = JSON.stringify(payload)
            console.log(url)
              fetch(url)
              .then((response)  => { return response.json()
              })
              .then((data) => {
                  
                  context.commit("setTxt1",data.txt1)
                  context.commit("setTituloTxt1",data.titulotxt1)
                  context.commit("setTxtParceiro",data.txtparceiro)
                  context.commit("setPoliticaTexto",data.txtpolitica)
                  context.commit("setEmail",data.email)
                  context.commit("setEmailPaypal",data.emailpaypal)
                  context.commit("setFone",data.fone)
                  context.commit("setTxtDescProg",data.txtdescprog)
                  context.commit("setVideoHome",data.videohome)
                  context.commit("setQtdParc",data.qtdparc)


              })
              .catch(function(error) {
                      console.log('Erro loadempresa site: '+error);
              })

        }, 
        logar(context,payload) {
             const url = process.env.VUE_APP_BACKEND +'ajax_logar2.php'
             const dataJson = 'email='+payload.email+'&senha='+payload.senha
             //const dataJson = JSON.stringify(payload)
            
               fetch(url,{
                    method: "POST",
                    headers: { "Content-Type":"application/x-www-form-urlencoded" },
                    body: dataJson
               })
               .then((response)  => { return response.json()
               })
               .then((data) => {
                  
                   context.commit("setNomeLog",data.nome)
                   context.commit("setLogado",data.sucesso)
                   context.commit("setClientId",data.cfid)
                   context.commit("setMsgLog",data.msg)
                   context.commit("setPolitica","accept") // se logou é porque ja aceitou a politica privacidade
 
               })
               .catch(function(error) {
                       console.log('Erro logando site: '+error);
               })
            
        },
        logout(context,payload) {
            context.commit("setLogado",payload)
            context.commit("setClientId",0)
            context.commit("setNomeLog","")
            
        },
        criarconta(context,payload) {
            const url = process.env.VUE_APP_BACKEND +'ajax_criaconta.php'

            const dados =   'id='+payload.id+'&'+
                            'nome='+payload.nome+'&'+
                            'cpf='+ payload.cpf+'&'+
                            'cnpj='+ payload.cnpj+'&'+
                            'razao='+payload.razao+'&'+
                            'email='+payload.email+'&'+
                            'senha='+payload.senha+'&'+
                            'tipopessoa='+payload.tipopessoa+'&'+
                            'endereco='+payload.endereco+'&'+
                            'endnum='+payload.endnum+'&'+
                            'compl='+payload.compl+'&'+
                            'bairro='+payload.bairro+'&'+
                            'cidade='+payload.cidade+'&'+ 
                            'uf='+payload.uf+'&'+
                            'cep='+payload.cep+'&'+
                            'telcom='+payload.telcom+'&'+
                            'celular='+payload.celular 
                            console.log(url)
                            fetch(url,{
                                 method: "POST",
                                 headers: { "Content-Type":"application/x-www-form-urlencoded" },
                                 body: dados
                            })
                            .then((response)  => { return response.json()
                            })
                            .then((data) => {
                                if (data.status == "sucesso") {
                                    context.commit("setNomeLog",payload.nome)
                                    context.commit("setLogado",true)
                                    context.commit("setClientId",data.id)

                                    if (data.message.includes('criada')) {
                                        console.log('vou dar dispatch no envia_email_modelo para id = '+data.id)
                                        // Se incluiu novo usuario do site enviar email para confirmar nova conta
                                        context.dispatch("envia_email_modelo",{
                                           cliente : data.id, // codigo novo cliente inserido via ajax
                                           modelo : 1,
                                           pedido : 0
     
                                        })     
                                     }

                                }
                                context.commit("setMsgConta",data.message)
              
                            })
                            .catch(function(error) {
                                    console.log('Erro Envio Criação da Conta: '+error);
                            })                            


        },
        envia_email_modelo(context,payload) {
            const url = process.env.VUE_APP_BACKEND +'ajax_envia_email_modelo.php'

            const dados =   'modelo='+payload.modelo+'&'+
                            'cliente='+ payload.cliente+'&'+
                            'pedido='+payload.pedido
            fetch(url,{
                method: "POST",
                headers: { "Content-Type":"application/x-www-form-urlencoded" },
                body: dados
            })
            .then((response)  => { return response.json()
            })
            .then((data) => {
                    console.log('Envio Email '+data.message);          
                   // context.commit("setEnviouParceiro",data.status)
                   // context.commit("setMsgParceiro",data.message)
             
            })
            .catch(function(error) {
                                   console.log('Erro Envio Parceiro: '+error);
            })                   

        },
        enviarparceiro(context,payload) {
            const url = process.env.VUE_APP_BACKEND +'ajax_enviaparceiro.php'

            const dados =   'nome='+payload.nome+'&'+
                            'cnpj='+ payload.cnpj+'&'+
                            'razao='+payload.razao+'&'+
                            'email='+payload.email+'&'+
                            'endereco='+payload.endereco+'&'+
                            'endnum='+payload.endnum+'&'+
                            'compl='+payload.compl+'&'+
                            'bairro='+payload.bairro+'&'+
                            'cidade='+payload.cidade+'&'+ 
                            'uf='+payload.uf+'&'+
                            'cep='+payload.cep+'&'+
                            'telcom='+payload.telcom+'&'+
                            'celular='+payload.celular 
                            
                            fetch(url,{
                                 method: "POST",
                                 headers: { "Content-Type":"application/x-www-form-urlencoded" },
                                 body: dados
                            })
                            .then((response)  => { return response.json()
                            })
                            .then((data) => {
                               
                                if (data.status == "sucesso") {
                                    console.log('vou dar dispatch no envia_email_modelo para id = '+data.id)
                                    // Se incluiu novo usuario do site enviar email para confirmar nova conta
                                    context.dispatch("envia_email_modelo",{
                                    cliente : data.id , // codigo Solicitacao da parceria inserido via ajax
                                    modelo : 6,  // Modelo Solicitacao de parceria
                                    pedido : 0

                                    })  
                                }    
                                context.commit("setEnviouParceiro",data.status)
                                context.commit("setMsgParceiro",data.message)
              
                            })
                            .catch(function(error) {
                                    console.log('Erro Envio Parceiro: '+error);
                            })                            


        },

    }


})