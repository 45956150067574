<template>
<b-container class='mt-5'>
 <b-row class="justify-content-md-center">
<b-col cols="12" md="auto">
<br><br>
 <b-card class="mt-5" header="Login"
        header-bg-variant="primary"
        header-text-variant="white"
 >
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-1"
      >
        <b-form-input
          id="input-1"
          v-model="form.email"
          type="email"
          placeholder="Digite seu email"
          required
          v-show="!logado"
        ></b-form-input>
      </b-form-group>
      <br>
      <b-form-group 
         id="input-group-2" >
        <b-form-input
          id="input-2"
          v-model="form.senha"
          type="password"
          placeholder="Digite sua senha"
          required
          v-show="!logado"
        ></b-form-input>
      </b-form-group>
        <br><br>
      <b-button v-show="!logado" type="submit" variant="primary">Entrar</b-button>
      <b-alert  class='mt-3' v-bind:show="temmsg" v-bind:variant="classemsg"> {{mensagem}}</b-alert>
   
    </b-form>

  </b-card>
  </b-col>
 </b-row>
</b-container>  
</template>
<script>
export default {
    data() {
      return {
        form: {
          email: '',
          senha: ''
        },
        
      }
    },
   watch: {
       logado: function(newValue) {
           if (!newValue) {
             this.senha = ""
           }
       }  
   },  
    computed: {
       logado() {
          return this.$store.getters.getLogado
        },

        mensagem() {
            return this.$store.getters.getMsgLog
        },
        temmsg() {
            let aux = this.mensagem
            if (aux.length > 0) {
                return true
            } else {
                return false
            }
        },
        classemsg() {
            if (this.logado) {
                return "success"
            } else {
               return "danger"   
            }
        }
    },
    methods: {
        onSubmit(event) {
            console.log(this.form)
            event.preventDefault()
            this.$store.dispatch('logar',this.form)
           
            
        }
    }   
}
</script>
<style scoped>
.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #00a69c !important;
}
</style>