<template>

  
    <div>
    <b-button v-b-modal.modal-prevent-closing variant="danger">Adicionar Cupom</b-button>

     <b-modal
      id="modal-prevent-closing"
      ref="modal"
      title="Submeta seu Cupom"
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
    >
      <form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group
          label="Cupom"
          label-for="name-input"
          invalid-feedback="Cupom tem que ser preenchido"
         
          :state="nameState"
        >
          <b-form-input
            id="name-input"
            v-model="cupom"
            :state="nameState"
            required
          ></b-form-input>
        </b-form-group>
      </form>
    </b-modal>
  </div>
 
</template>
<script>
export default {
    data() {
        return {
           nameState: null,
           percent:0
        }
    },
    computed: {
      cupom:{
            get() {
                return this.$store.state.cupom
            },
            set(valor) {
            
                this.$store.commit("setCupom",valor) 
                
            }
        },
       
       

    },
    methods: {
          checkFormValidity() {
        const valid = this.$refs.form.checkValidity()
        this.nameState = valid
        return valid
      },
      resetModal() {
       
        this.nameState = null
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.handleSubmit()
      },
      handleSubmit() {
        // Exit when the form isn't valid
        if (!this.checkFormValidity()) {
          return
        }
        this.validarCupom()
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-prevent-closing')
        })
      },
      validarCupom() {
        this.loading = true
        const url = process.env.VUE_APP_BACKEND + "getCupom.php";
        const dataJson = 'tag='+this.cupom
        fetch(url,{
                        method: "POST",
                        headers: { "Content-Type":"application/x-www-form-urlencoded" },
                        body: dataJson
                })
            .then((response) => {
            if (response.ok) {
            
                return response.json();
            }
            })
            .then((data) => {
                this.$store.commit("setPerCupom",data.percentual)
                this.$store.commit("setCupomVlr",data.cupomvlr)
                this.$store.commit("setParceiroPed",data.parceiroped)
                this.$store.commit("setCupomId",data.cupomid)
                this.loading = false
                this.percent = data.percentual
            })
            .catch(function (error) {
            console.log(error);
            });
        } 
    }
}

</script>

<style scoped>

</style>